import $ from 'jquery';
import Swiper, {Navigation} from 'swiper';

import {single_add_cart_lemon} from './../partials/add-to-cart';

import 'swiper/swiper.scss';  
//@import '~swiper/modules/pagination/pagination'; 
import 'swiper/modules/navigation/navigation.scss'; 
import './../../scss/partials/_product-item.scss';
import './../../scss/blocks/block-product-sale.scss';

$(document).ready(function() {

  var slides = document.getElementsByClassName("product-sale-slider-wrap");

  for (var i = 0; i < slides.length; i++) {
    //Distribute(slides.item(i));
    var productcategoryswiper = new Swiper(slides.item(i).getElementsByClassName('product-sale-swiper').item(0), {
      modules: [Navigation],
      spaceBetween: 16,
      loop: false,
      slidesPerView: "auto",
      breakpoints: {
        "768": {
          slidesPerView: 2,
        },
        "992": {
          slidesPerView: 3,
          spaceBetween: 24,
        },
        "1200": {
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: slides.item(i).getElementsByClassName('product-sale-swiper-button-next').item(0),
        prevEl: slides.item(i).getElementsByClassName('product-sale-swiper-button-prev').item(0),
      },
    });  
  }
  
  $('.product-sale-slider-wrap .single_add_cart_lemon').on('click',function(e){
  
    e.preventDefault();
    single_add_cart_lemon($(this));
  })

})